<template>
  <div class="addPage">
    <div class="container">
      <h1 class="addTitle">添加科室</h1>
      <el-form ref="addDeptForm" :model="addDeptForm" :rules="rules" label-width="100px">
        <el-form-item label="科室名称" prop="deptName">
          <el-input v-model="addDeptForm.deptName"></el-input>
        </el-form-item>
        <el-form-item label="办公室电话" prop="telephone">
          <el-input v-model="addDeptForm.telephone"></el-input>
        </el-form-item>
        <el-form-item label="所在区域" prop="location">
          <el-input v-model="addDeptForm.location"></el-input>
        </el-form-item>
        <div class="button-group">
          <el-button type="success" plain @click="addDept">添加</el-button>
          <el-button type="success" plain @click="getBack">取消</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "addDept",
  data() {
    return {
      addDeptForm: {
        deptName: "",
        telephone: "",
        location: "",
      },
      // 添加校验规则
      rules: {
        deptName: [
          { required: true, message: "请输入科室名称", trigger: "blur" }
        ],
        telephone: [
          { required: true, message: "请输入办公室电话", trigger: "blur" },
          { pattern: /^[0-9]+$/, message: "电话必须是数字", trigger: "blur" },
        ],
        location: [
          { required: true, message: "请输入所在区域", trigger: "blur" }
        ],
      },
    };
  },
  methods: {
    //取消按钮
    getBack() {
      // 跳转科室管理
      this.$router.push("/dept");
    },
    async addDept() {
      // 在添加之前进行表单校验
      this.$refs.addDeptForm.validate((valid) => {
        if (valid) {
          // 如果校验通过，则执行添加操作
          this.$store.dispatch("addDept", this.addDeptForm).then(res => {
            if (res == "ok") {
              this.$message({
                message: "添加成功",
                type: "success",
                showClose: true,
              });
              // 跳转科室管理
              this.$router.push("/dept");
            } else {
              this.$message({
                message: res,
                type: "error",
                showClose: false,
              });
            }
          }).catch(error => {
            console.error("添加失败", error);
          });
        } else {
          console.log("表单校验未通过");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.addPage {
  width: 100%;
  height: 100%;
  display: flex;
  background-size: cover;
  background-image: url(../../assets/h2.jpg);
  /*   overflow: hidden; 禁止滚动条 */
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  position: fixed;

  .container {
    box-shadow: 3px 3px 10px 3px rgba(199, 196, 196, 0.5);
    background-color: rgba(255, 255, 255, 0.5);
    height: 300px;
    border: 1px solid rgb(239, 239, 239);
    border-radius: 6px;
    left: 50%;
    // top: 50%;
    margin: 0;
    position: absolute;
    transform: translate(-50%, -50%);

    .el-form {
      margin: 20px;
    }

    .el-form-item {
      width: 700px;
    }

    // 新增按钮组的样式
    .button-group {
      display: flex;
      justify-content: center; // 使按钮在水平方向上居中
      margin-top: 20px;
    }

    // 调整按钮的样式
    .el-button {
      // 移除不再需要的定位样式
      margin: 0 10px; // 给按钮之间增加一些空间
      width: 150px;
    }


    .addTitle {
      font-size: larger;
      text-align: center;
      margin: 10px 5px 20px 5px;
    }
  }
}
</style>
