<template>
  <el-dialog title="挂号记录" :visible.sync="dialogTableVisible" width="60%" :modal-append-to-body="false">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column property="patientName" label="患者姓名" min-width="150"></el-table-column>
      <el-table-column property="deptName" label="科室名称" min-width="200"></el-table-column>
      <el-table-column property="createTime" label="挂号时间" min-width="200"></el-table-column>
      <el-table-column label="状态" min-width="100">
        <template slot-scope="scope">
          {{ formatStatus(scope.row.status) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="100">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" v-if="scope.row.status == 2"
            @click="download(scope.row)">下载诊断书</el-button>
          <el-button type="danger" size="mini" v-if="scope.row.status == 0"
            @click="deleteRecord(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>



<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tableData: [{}],
      dialogTableVisible: false,
    }
  }
  ,
  computed: {
    ...mapGetters(["recordData"]),
  },

  methods: {
    openRecordDialog(patientId) {
      this.dialogTableVisible = true;
      this.queryRecrd(patientId);

    },
    //查询挂号记录
    async queryRecrd(patientId) {
      const res = await this.$store.dispatch("queryRecordData", patientId);
      // if(res.isArray){
      //   this.tableData = res
      // }else{
      //   // console.log(res.type);
      this.tableData = res
      // }
    },
    //诊断状态展示
    formatStatus(status) {
      const statusMap = {
        0: '待诊',
        1: '诊断中',
        2: '诊断结束'
      };
      return statusMap[status] || '未知状态';
    },

    //删除挂号记录
    deleteRecord(record) {
      if (record.status != 0) {
        this.$message({
          message: "该科室状态不可删除",
          type: "warning",
          showClose: true,
        });
        return;
      }
      const isConfirmed = confirm("确定要删除: " + record.patientName + " 的 " + record.deptName + " 挂号记录吗");
      if (isConfirmed) {
        // 用户点击了确认，执行删除操作
        //执行删除操作
        const res = this.$store.dispatch("deletePatientRecord", record);
        //处理响应结果
        res.then(data => {
          if (data == 'ok') {
            this.$message({
              message: "删除成功",
              type: "success",
              showClose: true,
            });
          } else {
            this.$message({
              message: data,
              type: "error",
              showClose: true,
            });
          }
          this.queryRecrd(record.patientId);
        })
      }
    },
    // 下载
    async download(row) {
      try {
        const patientName = row.patientName;

        const deptName = row.deptName;

        const pdf = await this.$store.dispatch("downLoadPDF", row.id);
        //如果返回的不是流的形式
        if (pdf.type == "application/json") {
          this.$message({
            message: "诊断书已丢失",
            type: "error",
            showClose: false,
          });
          return false
        }
        // 以blob流将其打印出来
        //创建一个临时下载url
        const loadUrl = URL.createObjectURL(pdf);
        // 在页面上点击下载按钮创建一个临时的a链接，用于下载操作
        const link = document.createElement("a");
        // 下载的地址（从何处下载）
        link.download = patientName + '的' + deptName + '诊断书' + '.pdf';
        // 下载的url即为创建的loadUrl
        link.href = loadUrl;
        link.type = 'application/pdf';
        // 打开新的页面
        // link.target = '_blank'
        // 将link元素隐藏
        link.style.display = "none";
        // 将这个link元素添加到页面body中
        document.body.appendChild(link);
        // 触发下载事件
        link.click();
        // 下载完成移除临时url
        URL.revokeObjectURL(link.href);
        // 同样移除临时link
        document.body.removeChild(link);
      } catch (error) { }
    },

  }
};

</script>