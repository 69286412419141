<template>
  <header>
    <el-menu class="el-menu-demo" mode="horizontal" background-color="#80becb" text-color="#ffffff"
      active-text-color="#a94343">

      <el-menu-item index="1" @click="toHome">首页</el-menu-item>
      <el-menu-item index="2" @click="toDept">科室信息</el-menu-item>
      <el-menu-item index="3" @click="toPatient" v-if="isButtonVisible1">患者信息</el-menu-item>
      <el-menu-item index="4" @click="toTriage" v-if="isButtonVisible2">诊疗入口</el-menu-item>
      <!-- <el-menu-item index="5" @click="toDrugList">医药信息</el-menu-item>-->

      <el-menu-item index="8" @click="toFileList">共享文档</el-menu-item>
      <el-menu-item index="9" @click="toManagerDuty" v-if="isButtonVisible4">值班管理</el-menu-item>
      <el-menu-item index="10" @click="toManagerNotice">通知</el-menu-item>
      <el-menu-item index="11" @click="toManagerUser" v-if="isButtonVisible5">用户管理</el-menu-item>
      <el-menu-item index="12" @click="toAuth" v-if="isButtonVisible6">权限管理</el-menu-item>
      <el-submenu index="6" v-if="isButtonVisible3">
        <template slot="title">日志</template>
        <el-menu-item index="6-1" @click="toLog">登录日志</el-menu-item>
        <el-menu-item index="6-2" @click="toLog2">诊断日志</el-menu-item>
      </el-submenu>

      <!-- <el-submenu index="7"> -->
      <!-- <template slot="title">其他</template> -->
      <!-- <el-menu-item index="7-1" @click="toFileList">共享文档</el-menu-item> -->
      <!-- <el-menu-item index="7-2" @click="toManagerDuty">值班管理</el-menu-item> -->
      <!-- <el-menu-item index="7-3" @click="toManagerNotice">通知管理</el-menu-item> -->
      <!-- </el-submenu> -->
    </el-menu>
  </header>
</template>

<script>
import { getMenuIds } from "@/util/token";

export default {
  name: 'Header',
  data() {
    return {

    }
  },
  computed: {
    //能否看见菜单：患者信息
    isButtonVisible1() {
      // 检查menuIds中是否含有权限
      const menuIds = getMenuIds()
      return menuIds.some(id => [4].includes(id));
    },
    //能否看见菜单：诊疗入口
    isButtonVisible2() {
      // 检查menuIds中是否含有权限
      const menuIds = getMenuIds()
      return menuIds.some(id => [3].includes(id));
    },
    //能否看见菜单：日志
    isButtonVisible3() {
      // 检查menuIds中是否含有权限
      const menuIds = getMenuIds()
      return menuIds.some(id => [8].includes(id));
    },
    //能否看见菜单：值班管理
    isButtonVisible4() {
      // 检查menuIds中是否含有权限
      const menuIds = getMenuIds()
      return menuIds.some(id => [11].includes(id));
    },
    //能否看见菜单：用户管理
    isButtonVisible5() {
      // 检查menuIds中是否含有权限
      const menuIds = getMenuIds()
      return menuIds.some(id => [1].includes(id));
    },
    //能否看见菜单：权限管理
    isButtonVisible6() {
      // 检查menuIds中是否含有权限
      const menuIds = getMenuIds()
      return menuIds.some(id => [2].includes(id));
    },
  },

  methods: {

    //去首页 
    toHome() {
      this.$router.push('/')
    },

    //点击去科室管理页面
    toDept() {
      this.$router.push('/dept')
    },
    //点击去增加科室页面
    toAddDept() {
      this.$router.push('/addDept')
    },
    // 去药品管理
    toDrugList() {
      this.$router.push('/drugList')
    },
    //点击去药品审核
    toDrugCheck() {
      this.$router.push('/checkDrug')
    },
    // 去患者管理
    toPatient() {
      this.$router.push('/patientList')
    },
    // 去添加患者
    toAddPatient() {
      this.$router.push("/addPatient");
    },

    // 去诊疗管理
    toTriage() {
      this.$router.push('/triageList')
    },
    // 去日志页面
    toLog() {
      this.$router.push('/log')
    },

    // // 去日志页面
    toLog2() {
      this.$router.push('/log2')
    },
    // 去管理值班
    toManagerDuty() {
      this.$router.push('/dutyList')
    },
    //去通知管理
    toManagerNotice() {
      this.$router.push('/noticeList')
    },
    // 去文件管理
    toFileList() {
      this.$router.push('/fileList')
    },
    //去用户管理
    toManagerUser() {
      this.$router.push("/users");
    },
    //去权限管理
    toAuth() {
      this.$router.push("/auth");
    }
  },
};
</script>
<style scoped>
.el-menu-demo {
  justify-content: space-around;
  /* 或者使用 space-between */
}

.el-menu-item,
.el-submenu__title {
  flex-grow: 1;
  text-align: center;
  /* 使得文字居中 */
  font-size: 16px;
  /* 设置字体大小为16px */
}


.el-submenu .el-menu-item {
  background-color: #92c1c9;
  /* 子菜单项背景颜色，稍微比主菜单淡一些以区分 */
  margin: 5px 0px;
  /* 子菜单项之间的间距 */
  font-size: 16px;
  /* 设置字体大小为16px */
}

/* 当菜单项处于激活状态时的样式 */
.el-menu-item.is-active,
.el-submenu__title.is-active {
  background-color: #a94343;
  /* 激活项背景颜色，与active-text-color保持一致 */
  color: #ffffff !important;
  /* 激活项文本颜色 */
  font-size: 16px;
  /* 设置字体大小为16px */
}

/* 鼠标悬停在菜单项上时的样式 */
.el-menu-item:hover,
.el-submenu__title:hover {
  background-color: #a94343;
  /* 鼠标悬停背景颜色 */
  color: #ffffff !important;
  /* 鼠标悬停文本颜色 */
  font-size: 16px;
  /* 设置字体大小为16px */
}

/* 子菜单的样式调整 */
.el-menu--horizontal>.el-submenu .el-menu {
  border: 1px solid #a94343;
  /* 子菜单边框颜色 */
  font-size: 16px;
  /* 设置字体大小为16px */
}

.container {
  width: 100%;
  /* 使用100%宽度以充分利用可用空间 */
  max-width: 1200px;
  /* 或根据设计需求设置一个最大宽度 */
  margin: 0 auto;
  /* 添加自动外边距以在较宽屏幕上居中对齐 */
}
</style>
