<template>
    <el-dialog title="请输入用户密码" :visible.sync="showPasswordDialog" width="30%" @close="resetPasswordForm">
        <el-form ref="passwordForm">
            <el-form-item label="请输入密码" label-width="90px">
                <el-input v-model="passwordInfo.oldPassword" type="password" autocomplete="off"
                    placeholder="密码"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="showPasswordDialog = false">取消</el-button>
            <el-button type="primary" @click="confirmUpdate">确认</el-button>
        </span>
    </el-dialog>
</template>
<script>

export default {
    name: "",
    data() {
        return {
            showPasswordDialog: false, // 控制对话框显示
            passwordInfo: {
                oldPassword: '',
            },
            userInfo: {}
        };
    },
    computed: {

    },
    methods: {

        openUpdateDialog(data) {
            this.userInfo = data;
            this.showPasswordDialog = true;
        },
        async confirmUpdate() {

            if (this.passwordInfo.oldPassword) {
                console.log(this.passwordInfo);
                const result = await this.$store.dispatch("confirmPwd", this.passwordInfo)
                if (result == 'ok') {
                    //修改用户信息
                    const result = await this.$store.dispatch("updatePersonInfo", this.userInfo)
                    if (result == 'ok') {
                        this.showPasswordDialog = false; // 关闭对话框
                        this.$message.success("修改成功，请重新登陆");
                        // 退出登录
                        this.$store.dispatch("userLogout").then(result => { this.$router.push("/login"); })
                    } else {
                        this.$message.error(result)
                    }
                } else {
                    this.$message.error(result)
                    return
                }

            } else {
                this.$message.error('密码不能为空');
            }
        },
        resetPasswordForm() {
            this.passwordInfo.oldPassword = ''; // 关闭对话框时重置密码输入
        }
    }
}
</script>