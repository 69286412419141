<template>
  <div class="addPage">
    <div class="container">
      <h1 class="addTitle">添加患者</h1>
      <el-form ref="addPatientForm" :model="addPatientForm" :rules="rules" label-width="100px" :inline="true"
        size="small">
        <el-form-item :label="'患者姓名'" prop="patientName">
          <el-input v-model="addPatientForm.patientName"></el-input>
        </el-form-item>
        <el-form-item :label="'姓\xa0\xa0\xa0\xa0\xa0\xa0别'" prop="patientSex">
          <el-radio-group v-model="addPatientForm.patientSex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="0">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="'生日'" prop="patientBirthday">
          <el-col :span="11">
            <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" style="width: 190px"
              v-model="addPatientForm.patientBirthday"></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="身份证" prop="patientIdCard">
          <el-input v-model="addPatientForm.patientIdCard" maxlength="18"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="patientTelphone">
          <el-input type="tel" maxlength="11" v-model="addPatientForm.patientTelphone"></el-input>
        </el-form-item>
        <el-form-item label="住址" prop="patientAddress">
          <el-input v-model="addPatientForm.patientAddress"></el-input>
        </el-form-item>
        <div class="button-group">
          <el-button type="success" plain @click="validateForm">添加</el-button>
          <el-button type="success" plain @click="getBack">取消</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "addPatient",
  data() {
    return {
      addPatientForm: {
        patientName: "",
        patientSex: "",
        patientBirthday: "",
        patientIdCard: "",
        patientTelphone: "",
        patientAddress: "",
      },
      rules: {
        patientName: [
          { required: true, message: "请输入患者姓名", trigger: "blur" },
        ],
        patientSex: [
          { required: true, message: "请选择患者性别", trigger: "change" },
        ],
        // patientBirthday: [
        //   { required: true, message: "请选择患者生日", trigger: "change" },
        // ],
        patientIdCard: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
          { pattern: /^[0-9]+$/, message: "身份证必须是数字", trigger: "blur" },
          { pattern: /^[0-9]{18}$/, message: "身份证号码必须是18位数字", trigger: "blur" },
        ],
        patientTelphone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { pattern: /^[0-9]+$/, message: "电话必须是数字", trigger: "blur" },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ],
        // patientAddress: [
        //   { required: true, message: "请输入住址", trigger: "blur" },
        // ],
      },
    };
  },
  methods: {
    //取消按钮
    getBack() {
      // 跳转科室管理
      this.$router.push("/patientList");
    },
    async validateForm() {
      this.$refs.addPatientForm.validate(async (valid) => {
        if (valid) {
          this.addPatient();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async addPatient() {
      try {
        const data = this.addPatientForm;
        const res = await this.$store.dispatch("addPatient", data);
        if (res == "ok") {
          this.$message({
            message: "添加成功",
            type: "success",
            showClose: true,
          });
          this.$router.push("/patientList");
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.addPage {
  width: 100%;
  height: 100%;
  display: flex;
  background-size: cover;
  background-image: url(../../assets/h2.jpg);
  overflow: hidden;
  /* 禁止滚动条 */
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  position: fixed;

  .container {
    width: 680px;
    box-shadow: 3px 3px 10px 3px rgba(199, 196, 196, 0.5);
    background-color: rgba(255, 255, 255, 0.5);
    height: 300px;
    border: 1px solid rgb(239, 239, 239);
    border-radius: 6px;
    left: 50%;
    // top: 50%;
    margin: 0;
    position: absolute;
    transform: translate(-50%, -20%);

    .el-form {
      margin: 20px;
    }

    .el-form-item {
      width: 310px;
    }

    .el-form-item__content .el-textarea {
      width: 510px;
      position: absolute;
    }

    // 新增按钮组的样式
    .button-group {
      display: flex;
      justify-content: center; // 使按钮在水平方向上居中
      margin-top: 20px;
    }

    // 调整按钮的样式
    .el-button {
      // 移除不再需要的定位样式
      margin: 0 10px; // 给按钮之间增加一些空间
      width: 150px;
    }


    .addTitle {
      font-size: larger;
      text-align: center;
      margin: 10px 5px 20px 5px;
    }
  }
}
</style>