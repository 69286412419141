<template>
  <div class="addPage">
    <div class="container">
      <h1 class="addTitle">上传文件</h1>
      <form id="addForm" class="addForm" @submit.prevent="uploadFile">
        <input class="inputData" type="file" ref="fileInput" multiple />
        <button class="loadButton" type="submit">上传</button>
      </form>
    </div>

  </div>
</template>

<script>
export default {
  name: "addFile",
  data() {
    return {
      uploadProgress: 0,
    };
  },
  methods: {
    async uploadFile() {
      const files = this.$refs.fileInput.files;
      if (!files || files.length === 0) {
        this.$message({
          message: "文件不能为空",
          type: "error",
          showClose: true,
        });
        return;
      }

      try {
        let chunkSize = 20 * 1024 * 1024; // 20MB
        const uploadPromises = [];

        for (let file of files) {
          let { name, size } = file;
          let start = 0;
          let index = 0;

          while (start < size) {
            let end = Math.min(size, start + chunkSize);
            let blob = file.slice(start, end);
            start = end;

            let blobFile = new File([blob], name);
            const formData = new FormData();
            formData.append("uploadFiles", blobFile);
            formData.append("index", index.toString());

            // 将Promise添加到数组，后续进行并发控制上传
            uploadPromises.push(this.$store.dispatch("addFile", formData));

            index++;
          }
        }

        // 使用Promise.allSettled等待所有上传任务完成
        const results = await Promise.allSettled(uploadPromises);
        // 这里可以进一步处理results，例如过滤出成功或失败的项目

        this.$message({
          message: "文件上传成功",
          type: "success",
          showClose: true,
        });
        this.$router.push("/fileList");
      } catch (error) {
        // 处理上传过程中的异常
        console.error("上传过程中出现错误：", error);
        this.$message({
          message: "文件上传失败",
          type: "error",
          showClose: true,
        });
      }
    }

  },
};
</script>

<style lang="less" scoped>
.addPage {
  width: 100%;
  height: 100%;
  display: flex;
  background-size: cover;
  background-image: url(../../assets/h2.jpg);
  overflow: hidden;
  /* 禁止滚动条 */
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  position: fixed;

  .container {
    box-shadow: 3px 3px 10px 3px rgba(199, 196, 196, 0.5);
    background-color: rgba(255, 255, 255, 0.5);
    width: 500px;
    height: 200px;
    border: 1px solid rgb(211, 211, 211);
    border-radius: 6px;
    left: 50%;
    top: 30%;
    margin: 0;
    position: absolute;
    transform: translate(-50%, -50%);

    .addTitle {
      font-size: larger;
      text-align: center;
      margin: 10px 5px 20px 5px;
    }
  }

  .addForm {
    display: flex;
    height: 35px;
    align-items: center;
    // border: 1px solid #ceabab;
    padding: 2px 5px;
    border-radius: 5px;
  }

  .inputData::file-selector-button {
    /* 自定义样式 */
    margin-top: 2px;
    height: 32px;
    width: 80px;
    border: #40a0ffe6;
    border-radius: 5px;
    color: #fff;
    background-color: #40a0ffc0;
    cursor: pointer;
  }

  // 用伪类来给按钮加一个点击改变颜色
  .inputData::file-selector-button:active {
    background-color: #409eff;
  }

  .inputData {
    display: flex;
    width: 400px;
    align-items: center;
    padding: 2px 5px;
    height: 35px;
    border: 1px solid #b4bccc;
    border-radius: 5px 0px 0px 5px;
    transition: border-color 0.3s ease;
  }

  .inputData:focus {
    border: 1px solid #409eff;
  }

  .loadButton {
    width: 80px;
    height: 40.6px;
    background-color: #67c23a;
    border: none;
    cursor: pointer;
    border-radius: 0px 5px 5px 0px;
  }

  .loadButton:active {
    background-color: #5eb533;
  }
}
</style>