<template>
  <div>
    <el-dialog
      :visible.sync="dialogFormVisible"
      :modal-append-to-body="false"
      width="25%"
      style="align-items: center"
    >
      <el-form :model="PatientUpdateForm" :rules="rules" label-width="100px" size="small" ref="PatientUpdateFormRef">
        <el-form-item label="患者姓名" prop="patientName">
          <el-input v-model="PatientUpdateForm.patientName"></el-input>
        </el-form-item>

        <el-form-item label="身份证号"  prop="patientIdCard">
          <el-input v-model="PatientUpdateForm.patientIdCard" maxlength="18"></el-input>
        </el-form-item>

        <el-form-item label="患者性别" prop="patientSex">
          <el-radio-group v-model="PatientUpdateForm.patientSex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="0">女</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="患者生日" prop="patientBirthday">
          <el-col :span="11">
            <el-date-picker
              v-model="PatientUpdateForm.patientBirthday"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              style="width: 200px"
            ></el-date-picker>
          </el-col>
        </el-form-item>

        <el-form-item label="患者电话" prop="patientTelphone">
          <el-input v-model="PatientUpdateForm.patientTelphone"  maxlength="11"></el-input>
        </el-form-item>

        <el-form-item label="患者住址" prop="patientAddress">
          <el-input v-model="PatientUpdateForm.patientAddress"></el-input>
        </el-form-item>
        
        <el-button
          class="finishUpdate"
          type="primary"
          size="small"
          @click="updatePatient"
        >完成</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

  <script>
export default {
  name: "",
  data() {
    return {
      dialogFormVisible: false,  
      PatientUpdateForm:  {
        patientName: "",
        patientSex: "",
        patientBirthday: "",
        patientIdCard: "",
        patientTelphone: "",
        patientAddress: "",
      },
      rules: {
        patientName: [
          { required: true, message: "请输入患者姓名", trigger: "blur" },
        ],
        patientSex: [
          { required: true, message: "请选择患者性别", trigger: "change" },
        ],
        // patientBirthday: [
        //   { required: true, message: "请选择患者生日", trigger: "change" },
        // ],
        patientIdCard: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
          { pattern: /^[0-9]+$/, message: "身份证必须是数字", trigger: "blur" },
          { pattern: /^[0-9]{18}$/, message: "身份证号码必须是18位数字", trigger: "blur" },
],
        patientTelphone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { pattern: /^[0-9]+$/, message: "电话必须是数字", trigger: "blur" },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ],
        // patientAddress: [
        //   { required: true, message: "请输入住址", trigger: "blur" },
        // ],
      },
      // isModal:false
    };
  },
  methods: {
    openUpdateDialog(data) {
      this.dialogFormVisible = true;
      this.PatientUpdateForm = data;
    },

    //   修改患者数据
    async updatePatient() {
      try {
        this.$refs.PatientUpdateFormRef.validate((valid) => {
        if (valid) {
          const data = {
          patientName: this.PatientUpdateForm.patientName,
          patientIdCard: this.PatientUpdateForm.patientIdCard,
          patientSex: this.PatientUpdateForm.patientSex,
          patientId: this.PatientUpdateForm.patientId,
          patientBirthday: this.PatientUpdateForm.patientBirthday,
          patientTelphone: this.PatientUpdateForm.patientTelphone,
          patientAddress: this.PatientUpdateForm.patientAddress,
        }
        const res = this.$store.dispatch("updatePatient", data);
        if (res == "ok") {
          this.$message({
            message: "修改成功",
            type: "success",
            showClose: true,
          });
        }
        // 关闭dialog
        this.dialogFormVisible = false;
        } else {
          // 如果验证失败
          console.log('验证失败');
          return false;
        }
      });
        
      } catch (error) {}
    },
  },
};
</script>
  
  <style lang="less" scoped>
.el-input {
  width: 200px;
}
.finishUpdate {
  margin-left: 50%;
  transform: translateX(-50%);
}
</style>