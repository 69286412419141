<template>
  <div class="addPage">
    <div class="container">
      <h1 class="addTitle">通知添加</h1>
      <el-form ref="addNoticeForm" :model="addNoticeForm" label-width="100px" :rules="rules">
        <el-form-item label="通知标题" prop="noticeName">
          <el-input v-model="addNoticeForm.noticeName"></el-input>
        </el-form-item>
        <el-form-item label="通知内容" prop="noticeContent">
          <el-input type="textarea" v-model="addNoticeForm.noticeContent"></el-input>
        </el-form-item>
        <div class="button-group">
          <el-button type="success" plain @click="addNotice">添加</el-button>
          <el-button type="success" plain @click="getBack">取消</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "addNotice",
  data() {
    return {
      addNoticeForm: {
        noticeName: "",
        noticeContent: "",
      },
      rules: {
        noticeName: [
          { required: true, message: "请输入通知标题", trigger: "blur" }
        ],
        noticeContent: [
          { required: true, message: "请输入通知内容", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    getBack() {
      // 返回上级
      this.$router.go(-1);
    },
    async addNotice() {
      this.$refs.addNoticeForm.validate(valid => {
        if (valid) {
          this.$store.dispatch("addNotice", this.addNoticeForm).then(res => {
            console.log(res,"ddd");
            if (res === 'ok') {
              this.$message({
                message: "添加成功",
                type: "success",
                showClose: true,
              });
              // 跳转到通知列表页
              this.$router.push("/NoticeList");
            }
          }).catch(error => {
            alert(error.msg);
          });
        } else {
          console.log("表单验证失败");
        }
      });
    }

  },
};
</script>


<style lang="less" scoped>
.addPage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url(../../assets/h2.jpg);
  overflow: hidden;
  /* 禁止滚动条 */
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center; position: fixed;
}

.container {
  width: 700px;
  box-shadow: 3px 3px 10px 3px rgba(199, 196, 196, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgb(239, 239, 239);
  border-radius: 6px;
}

.addTitle {
  font-size: larger;
  text-align: center;
  margin: 10px 5px 20px 5px;
}

.el-form {
  margin: 20px;
}

.el-form-item {
  width: calc(100% - 40px);
}

.button-group {
  text-align: center;
  margin-top: 20px;
}

.el-button {
  margin: 0 10px;
  width: 150px;
}
</style>
