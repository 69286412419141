<!-- 非管理员用户修改个人信息 -->
<template>
  <div class="registPage">
      <div class="registBack">
      <!-- 导入el表单 -->
      <h1 class="registTitle">用户注册</h1>
      <el-form
        size="small"
        ref="registForm"
        :rules="rules"
        :model="registForm"
        class="registCotainer"
        label-width="80px"
      >
        <el-form-item label="用户名" prop="userName">
          <el-input
            v-model="registForm.userName"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>

        <el-form-item :label="'密\xa0\xa0\xa0码'" prop="password">
          <el-input
            type="password"
            v-model="registForm.password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>

        <el-form-item label="真实姓名" prop="realName">
          <el-input
            v-model="registForm.realName"
            placeholder="请输入真实姓名"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item label="版本" prop="version">
          <el-input
          type="number"
            v-model="registForm.version"
            placeholder="请输入版本"
          ></el-input>
        </el-form-item> -->

        <el-form-item :label="'性\xa0\xa0\xa0别'" prop="sex">
          <el-radio-group v-model="registForm.sex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="0">女</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item :label="'生\xa0\xa0\xa0日'" prop="birth">
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              v-model="registForm.birth"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
        </el-form-item>

        <el-form-item label="手机号" prop="mobile">
          <el-input
            v-model="registForm.mobile"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <el-form-item :label="'邮\xa0\xa0\xa0箱'" prop="email">
          <el-input
            v-model="registForm.email"
            placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>

        <!--  <el-form-item label="选择文件" prop="">
          <el-input type="file" v-model="registForm.realName"></el-input>
        </el-form-item> -->

        <el-form-item label="选择科室">
          <el-select
            style="width: 420px"
            placeholder="请选择科室"
            v-model="registForm.deptId"
          >
            <el-option
              v-for="(dept, index) in deptInfo"
              :key="dept.deptId"
              :label="dept.deptName"
              :value="dept.deptId"
              >{{ dept.deptName }}</el-option
            >
            <!-- <el-option label="脑科" value="脑科">脑科</el-option>s -->
          </el-select>
        </el-form-item>

        <el-form-item label="选择职位">
          <el-select
            style="width: 420px"
            multiple
            placeholder="请选择你的职位"
            v-model="registForm.authId"
          >
            <el-option
              v-for="(auth, index) in allAuths"
              :key="auth.authId"
              :label="auth.authDesc"
              :value="auth.authId"
              >{{ auth.authDesc }}</el-option
            >

          </el-select>
        </el-form-item>

        <el-form-item label="用户头像">
          <input
            type="file"
            name="uploadFile"
            multiple
            @change="handlerClick"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="userRegist">注册</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: "EditUserInfo",
    props: ['editForm'], // 接收editForm作为prop
    data() {
      return {
        // 使用props初始化数据
        form: this.editForm || {
          userName: "",
        password: "",
        deptId: "",
        authId: [],
        realName: "",
        birth: "",
        sex: "",
        mobile: "",
        email: "",
        image: "",
        },
        rules: {
          // 校验规则
        }
      };
    },
    methods: {
      updateUserInfo() {
        // 更新用户信息的逻辑
      },
      handlerAvatarChange(event) {
        // 处理头像更改逻辑
      },
      cancel() {
        // 取消编辑操作逻辑
      }
    },
    mounted() {
      // 如果需要，可以在这里进一步处理接收到的数据
    }
  };
  </script>
  
  <style lang="less" scoped>
  .registPage {
    width: 100%;
    height: 100%;
    /* background-size: contain; */
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../assets/ai.png);
  
    /* background: url(../../assets/loginBack.png); */
    position: relative;
  
    .registBack {
      width: 500px;
  
      border-radius: 15px;
      background-clip: border-box;
      background-color: aliceblue;
      border: 1px solid #eaeaea;
      margin: auto;
      padding: 30px;
      box-shadow: 0 0 25px #a9b2b6;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .registTitle {
        text-align: center;
        margin: 0px 5px 20px 5px;
      }
    }
    .el-form-item {
      margin-bottom: 15px;
    }
  }
  </style>
  