<template>
  <div class="updatePage">
    <div class="container">
      <h1 class="updateTitle">挂号</h1>
      <el-form ref="PatientRegistForm" :model="PatientRegistForm" :rules="rules" label-width="100px">
        <el-form-item label="患者编号">
          <el-input v-model="PatientRegistForm.patientId" readonly></el-input>
        </el-form-item>
        <el-form-item label="患者姓名">
          <el-input v-model="PatientRegistForm.patientName" readonly> </el-input>
        </el-form-item>
        <el-form-item label="选择科室" prop="deptId">
          <el-select placeholder="请选择科室" v-model="PatientRegistForm.deptId">
            <el-option v-for="(dept, index) in deptInfo" :key="dept.deptId" :label="dept.deptName" :value="dept.deptId">{{
              dept.deptName }}</el-option>
          </el-select>
        </el-form-item>
        <div class="button-group">
          <el-button type="success" plain @click="doneRegist">完成</el-button>
          <el-button type="success" plain @click="getBack">取消</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
export default {
  name: "PatientRegist",
  data() {
    return {
      PatientRegistForm: {
        patientId: "",
        patientName: "",
        deptId: "",
      },
      rules: {
        deptId: [{ required: true, message: "请选择科室", trigger: "blur" }],
      }
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    // 拿到在曾经用户注册页面存储在vuex中的科室数据
    ...mapGetters(["deptInfo"]),
  },
  methods: {
    //取消按钮
    getBack() {
      // 跳转
      this.$router.push("/patientList");
    },
  async getData() {
    try {
      this.PatientRegistForm = this.$route.query.data;
      await this.$store.dispatch("selectAllDepts");
    } catch (error) {
      console.error("获取数据失败", error);
      this.$message.error("获取数据失败");
    }
  },

  async doneRegist() {
    try {
      this.$refs.PatientRegistForm.validate(async (valid) => {
        if (valid) {
          const res = await this.$store.dispatch("patientRegister", this.PatientRegistForm);
          if (res === "ok") {
            this.$message.success("挂号成功");
            this.$router.push("/patientList");
          } else {
            this.$message.error(res);
          }
        }
      });
    } catch (error) {
      console.error("挂号失败", error);
      this.$message.error("挂号失败");
    }
  },
}}
</script>
  
<style lang="less" scoped >
.updatePage {
  width: 100%;
  height: 100%;
  display: flex;
  background-size: cover;
  background-image: url(../../assets/h2.jpg);
  overflow: hidden;
  /* 禁止滚动条 */
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  position: fixed;

  .container {
    box-shadow: 3px 3px 10px 3px rgba(199, 196, 196, 0.5);
    background-color: rgba(255, 255, 255, 0.5);
    height: 300px;
    border: 1px solid rgb(239, 239, 239);
    border-radius: 6px;
    left: 50%;
    // top: 50%;
    margin: 0;
    position: absolute;
    transform: translate(-50%, -50%);

    .el-form {
      margin: 20px;
    }

    .el-form-item {
      width: 700px;
    }
// 新增按钮组的样式
.button-group {
      display: flex;
      justify-content: center; // 使按钮在水平方向上居中
      margin-top: 20px;
    }

    // 调整按钮的样式
    .el-button {
      // 移除不再需要的定位样式
      margin: 0 10px; // 给按钮之间增加一些空间
      width: 150px;
    }


    .updateTitle {
      font-size: larger;
      text-align: center;
      margin: 10px 5px 20px 5px;
    }
  }
}
</style>