<template>
  <div class="userDetailsPage">
    <div class="container">
      <!-- 基础信息部分 -->
      <el-col :span="8">
        <el-card class="info-card" :body-style="{ padding: '20px' }">
          <template #header>
            <div class="card-header">基础信息</div>
          </template>
          <div class="card-content">

            <div class="info-section">
              <h2 class="info-title">科室</h2>
              <p>{{ user.deptName }}</p>
            </div>
            <div class="info-section">
              <h2 class="info-title">角色</h2>
              <p>{{ authsToString(user.auths) }}</p>
            </div>

            <div class="info-section">
              <h2 class="info-title">权限</h2>
              <p>{{ menusToString(user.menus) }}</p>
            </div>
          </div>
        </el-card>
      </el-col>

      <!-- 个人信息部分 -->
      <el-col :span="8">
        <el-card class="info-card" :body-style="{ padding: '20px' }">

          <template #header>
            <div class="card-header">个人信息</div>
          </template>
          <div class="card-content">
            <div class="avatar-container">
              <img class="avatar" :src="userImage" alt="用户头像" @click="triggerFileInput">
            </div>
            <input type="file" ref="fileInput" @change="handleFileChange" style="display: none;" />
            <el-form :model="editableUserInfo" :rules="rules" label-width="100px" size="small" ref="editableUserInfo">
              <el-form-item label="用户昵称" prop="userName">
                <el-input v-model="editableUserInfo.userName"></el-input>
              </el-form-item>
              <el-form-item label="真实姓名" prop="realName">
                <el-input v-model="editableUserInfo.realName"></el-input>
              </el-form-item>
              <el-form-item label="手机号码" prop="mobile">
                <el-input v-model="editableUserInfo.mobile" maxlength="11"></el-input>
              </el-form-item>
              <el-form-item label="出生年月" prop="birth">
                <el-date-picker v-model="editableUserInfo.birth" type="date" placeholder="选择日期"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="性别" prop="sex">
                <el-select v-model="editableUserInfo.sex" placeholder="请选择性别">
                  <el-option v-for="item in genderOptions" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="用户邮箱" prop="email">
                <el-input v-model="editableUserInfo.email"></el-input>
              </el-form-item>
              <el-form-item label="职称" prop="title" >
                <el-input v-model="editableUserInfo.title" placeholder="如有多个,请以;分割"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="toConfirmDialog">修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>

      <!-- 密码修改部分 -->
      <el-col :span="8">
        <el-card class="info-card" :body-style="{ padding: '20px' }">
          <template #header>
            <div class="card-header">密码修改</div>
          </template>
          <div class="card-content">
            <el-form :model="passwordInfo" :rules="rules1" ref="passwordForm" label-width="100px" size="small">
              <el-form-item label="原始密码" prop="oldPassword">
                <el-input type="password" v-model="passwordInfo.oldPassword"></el-input>
              </el-form-item>
              <el-form-item label="新密码" prop="newPassword">
                <el-input type="password" v-model="passwordInfo.newPassword"></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="confirmPassword">
                <el-input type="password" v-model="passwordInfo.confirmPassword"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="changePassword">修改密码</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>


    </div>
    <!-- 密码确认对话框 -->
    <PwdDialog ref="PwdDialog"></PwdDialog>
  </div>
</template>

<script>
let _fileObj;
import { getUserData } from '@/util/token';
import PwdDialog from "./PwdDialog.vue";
export default {
  data() {
    return {
      user: {},
      editableUserInfo: {
        userId: '',
        userName: '',
        realName: '',
        mobile: '',
        image: '',
        sex: '',
        birth: '',
        title: "",
        email: ''
      },
      rules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        realName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        birth: [{ required: true, message: "请选择日期", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { pattern: /^[0-9]+$/, message: "电话必须是数字", trigger: "blur" },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ],
        email: [{ required: false, message: "请输入邮箱", trigger: "blur" },
        { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" }],
      },

      passwordInfo: {
        userId: '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      passwordCheckStatus: '', // 新增，用于记录原始密码验证的状态
      rules1: {
        oldPassword: [
          { required: true, message: '请输入原始密码', trigger: 'blur' },
          {
            validator: async (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入原始密码'));
              } else {
                try {
                  const response = await this.verifyPassword();
                  if (response !== 'ok') {
                    callback(new Error(response));
                  } else {
                    callback()
                  }
                } catch (error) {
                  callback(new Error('验证失败'));
                }
              }
            },
            trigger: 'blur'
          }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.passwordInfo.newPassword) {
                callback(new Error('两次输入密码不一致！'));
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
      },
      userImage: require('@/assets/头像.png'),
      genderOptions: [{
        value: 1,
        label: '男'
      }, {
        value: 0,
        label: '女'
      }],
    };
  },
  components: {
    PwdDialog
  },
  created() {
    this.getUser();
  },
  methods: {
    // 加载头像图片
    userImg(name) {
      if (name) {
        this.userImage = name
      }
    },
    async getUser() {
      try {
        const userData = getUserData();
        if (!userData || !userData.userId) {
          console.error("No user data found");
          return; // 或者处理未找到用户数据的情况
        }
        const result = await this.$store.dispatch("getProfileUser", userData.userId);
        this.user = result;
        this.editableUserInfo.userId = result.userId;
        this.editableUserInfo.userName = result.userName;
        this.editableUserInfo.realName = result.realName;
        this.editableUserInfo.mobile = result.mobile;
        this.editableUserInfo.email = result.email;
        this.editableUserInfo.image = result.image;
        this.editableUserInfo.birth = result.birth;
        this.editableUserInfo.sex = result.sex;
        this.userImg(result.image);
      } catch (error) {
        console.error("Failed to get user data:", error);
        // 处理错误，可能是显示消息给用户或重定向
      }
    },
    // getBack() {
    //     this.$router.go(-1); 
    // },
    authsToString(auths) {
      if (!Array.isArray(auths)) {
        return '';
      } else {
        return auths.map(auth => auth.authDesc).filter(desc => !!desc).join(', ');
      }
    },
    menusToString(menus) {
      if (!Array.isArray(menus)) {
        return '';
      } else {
        return menus.map(menu => menu.menuName).filter(name => !!name).join(', ');
      }
    },

    triggerFileInput() {
      this.$refs.fileInput.click(); // 触发文件输入
    },
    async handleFileChange(e) {
      let uploadFile = e.target.files[0];
      // 将得到的文件列表转换成真正的数组
      // this.files = Array.from(uploadFile)
      _fileObj = uploadFile;
      let formData = new FormData();
      formData.append("uploadFile", _fileObj);
      const result = await this.$store.dispatch('userImg', formData)
      if (result == '头像格式不正确') {
        this.$message.error(result)
        return
      }
      //修改后的头像名
      this.editableUserInfo.image = result
      //展示修改后的头像
      this.userImage = result
    },
    toConfirmDialog() {
      this.$refs.editableUserInfo.validate((valid) => {
        if (valid) {
          this.$refs.PwdDialog.openUpdateDialog(this.editableUserInfo)
        } else {
          console.log("表单校验未通过");
          return false;
        }
      });
    },
    async verifyPassword() {
      // 异步验证逻辑
      const result = await this.$store.dispatch("confirmPwd", this.passwordInfo)
      return result;
    },
    changePassword() {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          this.passwordInfo.userId = this.user.userId;

          this.$store.dispatch("changePassword", this.passwordInfo)
            .then(result => {
              if (result == "ok") {
                this.$message.success("修改成功，请重新登陆");
                // 退出登录
                this.$store.dispatch("userLogout").then(result => { this.$router.push("/login"); })

              } else {
                this.$message.error("修改失败");
              }
            })
            .catch(error => {
              console.error("修改密码出错", error);
              this.$message.error("修改密码出错，请重试");
            });
        } else {
          console.log("表单校验未通过");
          return false;
        }
      });
    }
  }
};
</script>
<style scoped>
.info-card {
  min-height: 600px;
  /* 调整为适合的最小高度 */
  padding: 24px;
  /* 对内边距进行调整 */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.card-header {
  text-align: center;
  font-size: 20px;
  /* 调整标题字体大小 */
  font-weight: bold;
  /* 字体加粗 */
  color: #333;
  margin-bottom: 20px;
  /* 增加与内容的间距 */
}

.info-section {
  margin-bottom: 24px;
  /* 增加每个部分之间的间距 */
}

.info-title {
  font-size: 18px;
  /* 调整小标题的字体大小 */
  font-weight: bold;
  /* 小标题加粗 */
  color: #666;
  /* 调整颜色以区分内容 */
  margin-bottom: 12px;
  /* 调整与内容的间距 */
}

p {
  font-size: 16px;
  /* 调整段落文字的大小 */
  line-height: 1.6;
  /* 增加行高以改善阅读体验 */
  color: #444;
}

.card-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 内容靠左排列 */

}

.button-group {

  justify-content: center;
  /* 水平居中 */
  padding-left: 45%
}

.avatar-container {
  display: flex;
  /* 启用flex布局 */
  justify-content: center;
  /* 水平居中 */
  width: 100%;
  /* 确保占满整个容器宽度 */
  margin-bottom: 20px;
  /* 根据需要调整头像与下方内容的间距 */
}

.avatar {
  border: 1px solid rgb(158, 157, 157);
  width: 50px;
  height: 50px;
}
</style>