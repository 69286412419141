<template>
  <div>
    <el-dialog :visible.sync="dialogFormVisible" :modal-append-to-body="false" width="30%" @close="handleDialogChange">
      <el-form :model="UserUpdateForm" :rules="rules" label-width="120px" size="small" ref="userUpdateFormRef">
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="UserUpdateForm.userName" readonly></el-input>
        </el-form-item>

        <el-form-item label="姓名">
          <el-input v-model="UserUpdateForm.realName" readonly></el-input>
        </el-form-item>

        <el-form-item label="密码" prop="password" placeholder="如不输入则默认为123456">
          <el-input v-model="UserUpdateForm.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="选择科室" prop="deptId">
          <el-select placeholder="请选择科室" v-model="UserUpdateForm.deptId">
            <el-option v-for="(dept, index) in deptInfo" :key="dept.deptId" :label="dept.deptName"
              :value="dept.deptId">{{ dept.deptName }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择职位" prop="authId">
          <el-select multiple placeholder="请选择你的职位" v-model="UserUpdateForm.authId">
            <el-option v-for="auth in allAuths" :key="auth.authId" :label="auth.authDesc" :value="auth.authId">
              {{ auth.authDesc }}
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="用户类型" prop="type">
          <el-select v-model="UserUpdateForm.type" placeholder="请选择用户类型">
            <el-option label="管理员" :value="0"></el-option>
            <el-option label="非管理员" :value="1"></el-option>
          </el-select>
        </el-form-item>

        <div style="text-align: center;">
          <el-button type="primary" @click="validateForm">完成</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "UpdateUser",
  data() {
    return {
      UserUpdateForm: {
        userName: '',
        realName: '',
        password: '',
        deptId: '',
        authId: [],
        type: ''
      },
      dialogFormVisible: false,
      rules: {
        deptId: [{ required: true, message: "请选择科室", trigger: "change" }],

        authId: [{ required: true, message: "请选择职位", trigger: "change" }],
      },
    };
  },
  computed: {
    ...mapGetters(["deptInfo"]),
    ...mapState({
      allAuths: state => state.auth.allAuths,
    }),
  },
  async mounted() {
    // 等待加载所有科室的信息
    await this.$store.dispatch("selectAllDepts");
    // 等待加载所有权限信息（如果需要的话）
    await this.$store.dispatch("queryAllAuth");
  },
  methods: {
    openUpdateDialog(data) {
      this.$store.dispatch("queryAllAuth");
      this.UserUpdateForm = Object.assign({}, data, { authId: data.auths.map(auth => auth.authId) });
      this.dialogFormVisible = true;
    },
    validateForm() {
      this.$refs.userUpdateFormRef.validate(async (valid) => {
        if (valid) {
          await this.updateUser();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async updateUser() {
      try {
        this.UserUpdateForm.auths = this.UserUpdateForm.authId.map(item => ({ authId: item }));
        await this.$store.dispatch("updateUserByUserId", this.UserUpdateForm);
        this.$message({
          message: "修改成功",
          type: "success",
          showClose: true,
        });
        this.dialogFormVisible = false;
        this.handleDialogChange();
      } catch (error) {
        console.error("更新用户失败:", error);
        this.$message.error("更新失败，请重试");
      }
    },
    handleDialogChange() {
      this.$emit('refresh-data');
    },
  },
};
</script>

<style lang="less" scoped>
.el-input {
  width: 200px;
}

.finishUpdate {
  margin-left: 50%;
  transform: translateX(-50%);
}
</style>
