import { render, staticRenderFns } from "./FileList.vue?vue&type=template&id=66271059&scoped=true&"
import script from "./FileList.vue?vue&type=script&lang=js&"
export * from "./FileList.vue?vue&type=script&lang=js&"
import style0 from "./FileList.vue?vue&type=style&index=0&id=66271059&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66271059",
  null
  
)

export default component.exports