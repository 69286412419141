<template>
  <div>
    <el-dialog :visible.sync="dialogFormVisible" :modal-append-to-body="false" width="25%" style="align-items: center"
      @close="handleDialogChange">
      <el-form :model="editAuthForm" :rules="rules" ref="editAuthFormRef" label-width="100px" size="small">
        <el-form-item label="职位名称" prop="authName">
          <el-input v-model="editAuthForm.authName"></el-input>
        </el-form-item>

        <el-form-item label="职位描述" prop="authDesc">
          <el-input v-model="editAuthForm.authDesc"></el-input>
        </el-form-item>

        <el-form-item label="赋权">
          <el-select style="width: 200px" multiple placeholder="选择需要赋予的权限" v-model="editAuthForm.menuId">
            <el-option v-for="(aMenu, index) in allMenu" :key="aMenu.menuId" :label="aMenu.menuName"
              :value="aMenu.menuId">{{ aMenu.menuName }}</el-option>
          </el-select>
        </el-form-item>

        <el-button class="finishUpdate" type="primary" size="small" @click="updateAuths">完成</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  props: ["authId"],
  data() {
    return {
      editAuthForm: {
        authId: "",
        authName: "",
        authDesc: "",
        menuId: [],
      },
      dialogFormVisible: false,
      rules: {
        authName: [{ required: true, message: "请输入职位名称", trigger: "blur" },
        { pattern: /^[a-z]+$/, message: "权限名称必须是小写字母", trigger: "blur" }],
        authDesc: [{ required: true, message: "请输入职位描述", trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState({
      allMenu: (state) => state.auth.allMenu,
    }),
  },
  methods: {
    async openUpdateDialog(authId) {
      try {
        let result = await this.$store.dispatch("queryAuthByAuthId", authId);
        this.editAuthForm.authId=result.data.authId
        this.editAuthForm.authName=result.data.authName
        this.editAuthForm.authDesc=result.data.authDesc
        this.editAuthForm.menuId=result.data.menuIds
        this.dialogFormVisible = true;

        // 查询所有菜单
        this.$store.dispatch("reqAllMenu");
      } catch (error) {
        console.error("获取职位详情失败:", error);
      }
    },

    async updateAuths() {
      this.$refs.editAuthFormRef.validate(async (valid) => {
        if (valid) {
          try {
            const data = {
              authId: this.editAuthForm.authId,
              authName: this.editAuthForm.authName,
              authDesc: this.editAuthForm.authDesc,
              menus: this.editAuthForm.menuId.map(item => ({ menuId: item })),
            };
            const result = await this.$store.dispatch("updateAuth", data);
            if(result == "ok"){
             await this.$message.success("修改成功")
              this.$router.push("/auth");
            }else{
              this.$message.error(result)
              
            }
            this.dialogFormVisible = false;
            this.handleDialogChange();
          } catch (error) {
            console.error("更新失败:", error);
          }
        }
      });
    },

    handleDialogChange() {
      this.$emit("refresh-data");
    },
  },
  mounted() {
    if (this.authId) {
      this.openUpdateDialog(this.authId);
    }
  },
};
</script>

<style lang="less" scoped>
.el-input {
  width: 200px;
}

.finishUpdate {
  margin-left: 50%;
  transform: translateX(-50%);
}
</style>
