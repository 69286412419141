<template>
    <div class="addPage">
        <div class="container">
            <h1 class="addTitle">添加用户</h1>
            <el-form ref="addUserForm" :model="addUserForm" :rules="rules" label-width="100px" :inline="true"
                size="small">
                <el-form-item label="用户名" prop="userName">
                    <el-input v-model="addUserForm.userName"></el-input>
                </el-form-item>
                <el-form-item label="真实姓名" prop="realName">
                    <el-input v-model="addUserForm.realName"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password" >
                    <el-input type="password" v-model="addUserForm.password" placeholder="默认密码为6个1"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                    <el-input v-model="addUserForm.mobile" maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="选择科室">
                    <el-select placeholder="请选择科室" v-model="addUserForm.deptId">
                        <el-option v-for="(dept, index) in deptInfo" :key="dept.deptId" :label="dept.deptName"
                            :value="dept.deptId">{{ dept.deptName }}</el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择职位" prop="authId">
                    <el-select multiple placeholder="请选择你的职位" v-model="addUserForm.authId">
                        <el-option v-for="(auth, index) in allAuths" :key="auth.authId" :label="auth.authDesc"
                            :value="auth.authId">{{ auth.authDesc }}</el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户类型" prop="type">
                    <el-select v-model="addUserForm.type" placeholder="请选择用户类型">
                        <el-option label="管理员" :value="0"></el-option>
                        <el-option label="非管理员" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <div class="button-group">
                    <el-button type="success" plain @click="validateForm">添加</el-button>
                    <el-button type="success" plain @click="getBack">取消</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import axios from 'axios';

export default {
    name: "AddUser",
    data() {
        return {
            addUserForm: {
                userName: "",
                password: "",
                auths: [],
                email: "",
                authId: [],
                deptId: "",
                realName: "",
                mobile: "",
                type: "",
            },
            rules: {
                userName: [{ required: true, message: "请输入用户名", trigger: "blur" },
                { validator: this.checkUsernameAvailability, trigger: 'blur' }
            ],
                email: [
                    { required: true, message: "请输入邮箱", trigger: "blur" },
                    { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" }
                ],
                authId: [{ required: true, message: "请选择职位", trigger: "change" }],
                status: [{ required: true, message: "请选择状态", trigger: "change" }],
                realName: [{ required: true, message: "请输入真实姓名", trigger: "blur" }], // 真实姓名的验证规则
                mobile: [
                    { required: true, message: "请输入手机号码", trigger: "blur" },
                    { pattern: /^[0-9]+$/, message: "电话必须是数字", trigger: "blur" },
                    { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
                ], // 手机号的验证规则
                type: [{ required: true, message: "请选择用户类型", trigger: "change" }], // 用户类型的验证规则
            },
        };
    },
    computed: {
        ...mapGetters(["deptInfo"]),
        ...mapState({
            allAuths: (state) => state.auth.allAuths,
        }),
    },
    async mounted() {
        // 等待加载所有科室的信息
        await this.$store.dispatch("selectAllDepts");
        // 等待加载所有权限信息（如果需要的话）
        await this.$store.dispatch("queryAllAuth");
        // 设置 deptId 为路由参数或其他逻辑确定的值
        this.addUserForm.deptId = this.$route.params.deptId ? parseInt(this.$route.params.deptId, 10) : null;
    },

    methods: {
        checkUsernameAvailability(rule, value, callback) {
            if (!value) {
                return callback(new Error('用户名不能为空'));
            }
            axios.get('/api/user/check-username', { params: { username: value } })
                .then(response => {
                    if (response.data) {
                        callback();
                    } else {
                        callback(new Error('用户名已存在'));
                    }
                })
                .catch(() => {
                    callback(new Error('验证用户名时发生错误'));
                });
        },
        validateForm() {
            this.$refs.addUserForm.validate((valid) => {
                if (valid) {
                    alert('提交成功!');
                } else {
                    console.log('输入有误!');
                    return false;
                }
            });
        },
        getBack() {
            // 返回上级
            this.$router.go(-1); 
        },
        validateForm() {
            this.$refs.addUserForm.validate(valid => {
                if (valid) {
                    this.addUser();
                } else {
                    console.log("表单验证失败");
                }
            });
        },
        async addUser() {
            this.addUserForm.auths = this.addUserForm.authId.map((item) => ({
                authId: item,
            }));

            try {
                // 调用接口添加用户
                const res = await this.$store.dispatch("userRegist", this.addUserForm);
                if (res === "ok") {
                    this.$message({
                        message: "用户添加成功",
                        type: "success",
                        showClose: true,
                    });
                    // 添加成功后跳转到用户列表页
                    this.$router.push("/users");
                }
            } catch (error) {
                console.error("添加用户失败", error);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.addPage {
  width: 100%;
  height: 100%;
  display: flex;
  background-size: cover;
  background-image: url(../../assets/h2.jpg);
  overflow: hidden;
  /* 禁止滚动条 */
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  position: fixed;

  .container {
    width: 680px;
    box-shadow: 3px 3px 10px 3px rgba(199, 196, 196, 0.5);
    background-color: rgba(255, 255, 255, 0.5);
    height: 340px;
    border: 1px solid rgb(239, 239, 239);
    border-radius: 6px;
    left: 50%;
    margin: 0;
    position: absolute;
    transform: translate(-50%, -20%);

    .el-form {
      margin: 20px;
    }

    .el-form-item {
      width: 310px;
    }

    .el-form-item__content .el-textarea {
      width: 510px;
      position: absolute;
    }

    .button-group {
      display: flex;
      justify-content: center; 
      margin-top: 20px;
    }

    .el-button {
      margin: 0 10px;
      width: 150px;
    }


    .addTitle {
      font-size: larger;
      text-align: center;
      margin: 10px 5px 20px 5px;
    }
  }
}
</style>