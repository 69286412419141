<template>
  <div class="updatePage">
    <div class="container">
      <h1 class="updateTitle">科室修改</h1>
      <el-form :model="updateDeptForm" :rules="rules" ref="updateDeptForm" label-width="100px">
        <el-form-item label="科室名称" prop="deptName">
          <el-input v-model="updateDeptForm.deptName"></el-input>
        </el-form-item>
        <el-form-item label="办公室电话" prop="telephone">
          <el-input v-model="updateDeptForm.telephone"></el-input>
        </el-form-item>
        <el-form-item label="所在区域" prop="location">
          <el-input v-model="updateDeptForm.location"></el-input>
        </el-form-item>
        <div class="button-group">
          <el-button type="success" plain @click="updateDept">修改</el-button>
          <el-button type="success" plain @click="getBack">取消</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "updateDept",
  data() {
    return {
      updateDeptForm: {},
      rules: {
        deptName: [
          { required: true, message: "请输入科室名称", trigger: "blur" }
        ],
        telephone: [
          { required: true, message: "请输入办公室电话", trigger: "blur" },
          { pattern: /^[0-9]+$/, message: "电话必须是数字", trigger: "blur" },
        ],
        location: [
          { required: true, message: "请输入所在区域", trigger: "blur" }
        ]
      },
    };
  },
  mounted() {
    this.getDeptList();
  },
  computed: {
    ...mapGetters(["singleData"]),
  },
  methods: {
    async getDeptList() {
      try {
        const deptId = this.$route.params.deptId;
        await this.$store.dispatch("showDeptById", deptId);
        const oneData = JSON.parse(JSON.stringify(this.singleData));
        this.updateDeptForm = oneData;
      } catch (error) { }
    },

    //取消修改
    getBack() {
      // 跳转科室管理
      this.$router.push("/dept");
    },

    //修改
    async updateDept() {
      this.$refs.updateDeptForm.validate(async (valid) => {
        if (valid) {
          try {
            const res = await this.$store.dispatch("updateDept", this.updateDeptForm);
            if (res == "ok") {
              this.$message({
                message: "修改成功",
                type: "success",
                showClose: true,
              });
              // 跳转科室管理
              this.$router.push("/dept");
            } else {
              this.$message({
                message: res,
                type: "error",
                showClose: false,
              });
            }
          } catch (error) {
            // 错误处理
          }
        } else {
          console.log('表单验证失败');
          return false;
        }
      });
    },
  },
};
</script>


<style lang="less" scoped>
.updatePage {
  width: 100%;
  height: 100%;
  display: flex;
  background-size: cover;
  background-image: url(../../assets/h2.jpg);
  overflow: hidden;
  /* 禁止滚动条 */
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  position: fixed;

  .container {
    box-shadow: 3px 3px 10px 3px rgba(199, 196, 196, 0.5);
    background-color: rgba(255, 255, 255, 0.5);
    height: 300px;
    border: 1px solid rgb(239, 239, 239);
    border-radius: 6px;
    left: 50%;
    // top: 50%;
    margin: 0;
    position: absolute;
    transform: translate(-50%, -50%);

    .el-form {
      margin: 20px;
    }


    .el-form-item {
      width: 700px;
    }

    // 新增按钮组的样式
    .button-group {
      display: flex;
      justify-content: center; // 使按钮在水平方向上居中
      margin-top: 20px;
    }

    // 调整按钮的样式
    .el-button {
      // 移除不再需要的定位样式
      margin: 0 10px; // 给按钮之间增加一些空间
      width: 150px;
    }

    .updateTitle {
      font-size: larger;
      text-align: center;
      margin: 10px 5px 20px 5px;
    }
  }
}
</style>