<template>
  <div class="container">
    <div class="tableList">
      <el-button type="primary" plain style="width: 100px; height: 35px; font-size: 10px; text-align: center"
        @click="toAddAuth">+添加职位</el-button>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column :label="'职位/权限'" align="center">
          <el-table-column type="index" width="100" align="center" label="序列">
          </el-table-column>

          <el-table-column prop="authName" label="职位名称" width="180" align="center">
          </el-table-column>

          <el-table-column prop="authDesc" label="职位描述" width="180" align="center">
          </el-table-column>

          <el-table-column label="操作" width="150" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="toUpdateAuth(scope.row.authId)">编辑</el-button>
              <el-button size="mini" type="danger" @click="deleteThisRow(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加一个自定义事件，以在dialog关闭时触发重新刷新页面数据 -->
    <authUpdateDialog ref="authUpdate" @refresh-updateData="refreshUpdateData"></authUpdateDialog>
    <addAuthDialog ref="addAuth" @refresh-data="refreshData"></addAuthDialog>
  </div>
</template>

<script>
import addAuthDialog from "./addAuthDialog.vue";
import { mapGetters, mapState } from "vuex";
import authUpdateDialog from "./authUpdateDialog.vue";
export default {
  components: {
    addAuthDialog,
    authUpdateDialog
  },
  data() {
    return {
      tableData: [],
      realName: null,
    };
  },
  mounted() {
    this.getAuth();
  },
  computed: {
    ...mapState({
      allAuths: (state) => state.auth.allAuths,
    }),
  },

  methods: {

    // 查询数据并显示
    async getAuth() {
      try {
        await this.$store.dispatch("queryAllAuth");
        this.tableData = this.allAuths;
      } catch (error) { }
    },

    // 删除这条数据
    async deleteThisRow(row) {
      if (confirm('确认删除' + row.authDesc + '这个职位吗')) {
        try {
          const res = await this.$store.dispatch("deleteAuth", row.authId);
          if (res == "ok") {
            this.$message({
              message: "删除成功",
              type: "success",
              showClose: true,
            });
            this.getAuth();

          } else {
            this.$message({
              message: res,
              type: "error",
            });
          }
        } catch (error) { }
      }
    },

    // 去添加数据
    toAddAuth() {
      this.$refs.addAuth.openAddDialog()
    },

    // 点击修改去修改
  toUpdateAuth(authId) {
     this.$refs.authUpdate.openUpdateDialog(authId);
    },

    //自定义刷新数据事件
    refreshData() {
      this.getAuth()
    },
    refreshUpdateData() {
      this.getAuth()
    }
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  position: fixed;

  .tableList {
    height: 100%;
    position: absolute;
    // width: 100%;
    left: 50%;
    transform: translate(-50%, 20%);
  }

  .pagination {
    position: absolute;
    left: 50%;
    margin-top: 15px;
    transform: translateX(-50%);
  }
}
</style>