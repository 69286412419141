<template>
  <div class="updatePage">
    <div class="container">
      <h1 class="updateTitle">通知修改</h1>
      <el-form
        ref="updateNoticeForm"
        :model="updateNoticeForm"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item label="通知标题" prop="noticeName">
          <el-input v-model="updateNoticeForm.noticeName"></el-input>
        </el-form-item>
        <el-form-item label="通知内容" prop="noticeContent">
          <el-input
            type="textarea"
            v-model="updateNoticeForm.noticeContent"
          ></el-input>
        </el-form-item>
        <div class="button-group">
          <el-button type="success" plain @click="updateNotice">完成</el-button>
          <el-button type="success" plain @click="getBack">取消</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

  
  <script>
import { mapGetters } from "vuex";
export default {
  name: "updateNotice",
  data() {
    return {
      updateNoticeForm: {
        noticeName: "",
        noticeContent: "",
      },
      rules: {
        noticeName: [
          { required: true, message: "请输入通知标题", trigger: "blur" }
        ],
        noticeContent: [
          { required: true, message: "请输入通知内容", trigger: "blur" }
        ]
      }
    };
  },
  mounted() {
    this.queryData();
  },
  computed: {
    noticeId() {
      return this.$route.query.noticeId;
    },
    ...mapGetters(["noticeData2"]),
  },
  methods: {
    getBack() {
      // 返回上级
      this.$router.go(-1);
    },
    // 根据id查询数据
    async queryData() {
      try {
        await this.$store.dispatch("updateQueryNotice", this.noticeId);
        this.updateNoticeForm = this.noticeData2;
      } catch (error) {}
    },

    // 修改通知
    async updateNotice() {
      await this.$refs.updateNoticeForm.validate(); // 校验表单
      try {
        const data = this.updateNoticeForm;
        const result = await this.$store.dispatch("updateNotice", data);
        if (result == "ok") {
          this.$message({
            message: "修改成功",
            type: "success",
            showClose: true,
          });
        }
          this.$router.push("/noticeList");
        
      } catch (error) {}
    },
  },
};
</script>
  <style lang="less" scoped>
.updatePage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url(../../assets/h2.jpg);
  overflow: hidden;
  /* 禁止滚动条 */
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 700px;
  box-shadow: 3px 3px 10px 3px rgba(199, 196, 196, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgb(239, 239, 239);
  border-radius: 6px;
}

.updateTitle {
  font-size: larger;
  text-align: center;
  margin: 10px 5px 20px 5px;
}

.el-form {
  margin: 20px;
}

.el-form-item {
  width: calc(100% - 40px);
}

.button-group {
  text-align: center;
  margin-top: 20px;
}

.el-button {
  margin: 0 10px;
  width: 150px;
}
</style>