<template>
  <div class="resultPage">
    <div class="container">
      <h1 class="resultTitle">诊断</h1>
      <el-form ref="resultForm" :model="resultForm" :rules="rules" label-width="100px" size="small">
        <!-- <el-form-item label="患者编号">
          <el-input v-model="resultForm.patientId" readonly></el-input>
        </el-form-item> -->
        <el-form-item label="患者姓名">
          <el-input v-model="resultForm.patientName" readonly></el-input>
        </el-form-item>
        <!-- <el-form-item label="科室编号">
          <el-input v-model="resultForm.deptId" readonly></el-input>
        </el-form-item> -->
        <el-form-item :label="'科\xa0\xa0\xa0\xa0\xa0\xa0\xa0室'">
          <el-input v-model="resultForm.deptName" readonly></el-input>
        </el-form-item>
        <el-form-item label="医生姓名">
          <el-input v-model="resultForm.realName" readonly></el-input>
        </el-form-item>
        <el-form-item label="症状描述" prop="symptomDesc">
          <el-input type="textarea" v-model="resultForm.symptomDesc" placeholder="请描述患者症状" :rows="3"></el-input>
        </el-form-item>
        <el-form-item label="诊疗意见" prop="symptomIdea">
          <el-input type="textarea" v-model="resultForm.symptomIdea" placeholder="请输入诊疗意见，如药方、注意事项等"
            :rows="5"></el-input>
        </el-form-item>
        <div class="button-group">
          <el-button type="success" plain @click="submitResult">完成</el-button>
          <el-button type="success" plain @click="getBack">取消</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "addDept",
  data() {
    return {
      resultForm: {
        patientId: "",
        patientName: "",
        deptId: "",
        deptName: "",
        userId: "",
        realName: "",
        symptomDesc: "",
        symptomIdea: "",
      },
      rules: {
        symptomDesc: [
          { required: true, message: '请输入症状描述', trigger: 'blur' }
        ],
        symptomIdea: [
          { required: true, message: '请输入诊疗意见', trigger: 'blur' }
        ],
      },
    };
  },
  computed: {
    queryData() {
      return this.$route.query;
    },
  },
  mounted() {
    this.showData();
  },
  methods: {
    getBack() {
            // 返回上级
            this.$router.go(-1); 
        },
    showData() {
      // console.log(this.$route.query);
      this.resultForm = this.queryData;
    },

    // 提交诊断
    submitResult() {
      this.$refs.resultForm.validate((valid) => {
        if (valid) {
          const id = this.resultForm.id;
          const diagnosisResult = this.resultForm;
          this.$store.dispatch("resultPatient", {
            id,
            diagnosisResult,
          })
            .then((res) => {
              if (res == "ok") {
                this.$message({
                  message: "诊断完成",
                  type: "success",
                  showClose: true,
                });
              } else {
                this.$message({
                  message: res,
                  type: "error",
                  showClose: true,
                });
              }
              this.$router.push({
                name: "triageDetail",
                params: { deptId: this.resultForm.deptId },
                query: { deptName: this.resultForm.deptName },
              });
            })
            .catch((error) => {
              console.error("操作失败:", error);
              // 处理错误
            });
        } else {
          console.log("表单校验失败");
          return false;
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.resultPage {
  width: 100%;
  height: 100%;
  display: flex;
  background-size: cover;
  background-image: url(../../assets/h2.jpg);
  overflow: hidden;
  /* 禁止滚动条 */
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  position: fixed;

  .container {
    box-shadow: 3px 3px 10px 3px rgba(199, 196, 196, 0.5);
    background-color: rgba(255, 255, 255, 0.5);
    width: 500px;
    height: 500px;
    border: 1px solid rgb(239, 239, 239);
    border-radius: 6px;
    left: 50%;
    top: 45%;
    margin: 0;
    position: absolute;
    transform: translate(-50%, -50%);

    .el-form {
      margin: 20px;
    }

    .el-form-item {
      width: 420px;
    }

    .button-group {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    .el-button {
      margin: 0 10px;
      width: 150px;
    }

    .resultTitle {
      font-size: larger;
      text-align: center;
      margin: 15px 5px 20px 5px;
    }
  }
}
</style>