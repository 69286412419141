<template>
  <div>
    <el-dialog :visible.sync="dialogFormVisible" :modal-append-to-body="false" width="25%" style="align-items: center"
      @close="handleDialogChange">
      <el-form :model="addAuthForm" :rules="rules" label-width="100px" size="small" ref="addAuthFormRef">
        <el-form-item label="职位名称" prop="authName">
          <el-input v-model="addAuthForm.authName" placeholder="请输入英文描述"></el-input>
        </el-form-item>

        <el-form-item label="职位描述" prop="authDesc">
          <el-input v-model="addAuthForm.authDesc" placeholder="请输入中文描述"></el-input>
        </el-form-item>

        <el-form-item label="赋权">
          <el-select style="width: 200px" multiple placeholder="选择需要赋予的权限" v-model="addAuthForm.menuId">
            <el-option v-for="(aMenu, index) in allMenu" :key="aMenu.menuId" :label="aMenu.menuName"
              :value="aMenu.menuId">{{ aMenu.menuName }}</el-option>
          </el-select>
        </el-form-item>

        <el-button class="finishUpdate" type="primary" size="small" @click="addAuths">完成</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  data() {
    return {
      addAuthForm: {
        authId: "",
        authName: "",
        authDesc: "",
        menuId: [],
      },
      dialogFormVisible: false,
      rules: {
        authName: [
          { required: true, message: "请输入职位名称", trigger: "blur" },
          { pattern: /^[a-z]+$/, message: "权限名称必须是小写字母", trigger: "blur" }
        ],
        authDesc: [
          { required: true, message: "请输入职位描述", trigger: "blur" },
        ],
      }
    };
  },
  computed: {
    ...mapState({
      allAuths: (state) => state.auth.allAuths,
      allMenu: (state) => state.auth.allMenu,
    }),
  },
  methods: {
    openAddDialog() {
      this.dialogFormVisible = true;
      this.$store.dispatch("reqAllMenu");
    },

    async addAuths() {
      this.$refs.addAuthFormRef.validate(async (valid) => {
        if (valid) {
          try {
            const menuIdArray = this.addAuthForm.menuId.map((item) => ({
              menuId: item,
            }));

            const data = {
              authId: this.addAuthForm.authId,
              authName: this.addAuthForm.authName,
              authDesc: this.addAuthForm.authDesc,
              menus: menuIdArray,
            };
            const res = await this.$store.dispatch("addAuth", data);
            if (res == 'ok') {
              this.$message.success("添加成功")
              this.addAuthForm = {}
              this.dialogFormVisible = false;
            }else{
              this.$message.error(res)
            }

          } catch (error) {
            console.log(error);
          }
        }
      });
    },

    handleDialogChange() {
      this.$emit("refresh-data");
    },
  },
};
</script>

<style lang="less" scoped>
.el-input {
  width: 200px;
}

.finishUpdate {
  margin-left: 50%;
  transform: translateX(-50%);
}
</style>